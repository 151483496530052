<template>
  <svg
    width="18"
    height="12"
    viewBox="0 0 18 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.80385 11.6145C3.65394 11.6145 0.146167 7.39842 0 0.382812H3.08058C3.18177 5.53208 5.4528 7.71321 7.25166 8.16293V0.382812H10.1524V4.82375C11.9288 4.63262 13.7949 2.60892 14.4245 0.382812H17.3253C16.8419 3.1261 14.8181 5.14981 13.379 5.98178C14.8181 6.65636 17.123 8.42151 18 11.6145H14.8069C14.1211 9.47836 12.4123 7.82564 10.1524 7.60077V11.6145H9.80385Z"
    />
  </svg>
</template>
